<template>
  <div>

    <header-profil to="professional"></header-profil>

    <!-- <v-card class="">
      <header-profil to="professional" :profil="company"></header-profil>
      <v-tabs v-model="active_tab" hide-slider elevation="0">
        <v-tab v-for="(tab, index) of tabs" :key="index" :to="tab.link" exact class="tab-style"
          active-class='primaryAccent'>
          <v-icon size="20" color="primary" class="me-3">
            {{ tab.icon }}
          </v-icon>
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>
      <router-view></router-view>
    </v-card> -->
    <ul class="nav-dash-tab my-4">
      <li v-for="(tab, i) in tabs" :key="i" class="font-bold uppercase" v-on:click="toggleTabs(i + 1)"
        v-bind:class="{ 'nav-dash-tab-item': openTab !== (i + 1), 'nav-dash-tab-item-active': openTab === (i + 1) }">
        {{ tab.title }}
      </li>
    </ul>

    <div v-bind:class="{ 'hidden': openTab !== 1, 'block': openTab === 1 }">
      <!-- <div class="flex flex-wrap -mx-6">
        <div class="w-full px-6 sm:w-1/2 xl:w-1/3">
          <div class="flex items-center px-4 py-4 shadow-sm rounded-md bg-white">
            <div class="p-3 rounded-full secondary bg-opacity-75">
              <svg class="h-8 w-8 text-white" viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M18.2 9.08889C18.2 11.5373 16.3196 13.5222 14 13.5222C11.6804 13.5222 9.79999 11.5373 9.79999 9.08889C9.79999 6.64043 11.6804 4.65556 14 4.65556C16.3196 4.65556 18.2 6.64043 18.2 9.08889Z"
                  fill="currentColor"></path>
                <path
                  d="M25.2 12.0444C25.2 13.6768 23.9464 15 22.4 15C20.8536 15 19.6 13.6768 19.6 12.0444C19.6 10.4121 20.8536 9.08889 22.4 9.08889C23.9464 9.08889 25.2 10.4121 25.2 12.0444Z"
                  fill="currentColor"></path>
                <path
                  d="M19.6 22.3889C19.6 19.1243 17.0927 16.4778 14 16.4778C10.9072 16.4778 8.39999 19.1243 8.39999 22.3889V26.8222H19.6V22.3889Z"
                  fill="currentColor"></path>
                <path
                  d="M8.39999 12.0444C8.39999 13.6768 7.14639 15 5.59999 15C4.05359 15 2.79999 13.6768 2.79999 12.0444C2.79999 10.4121 4.05359 9.08889 5.59999 9.08889C7.14639 9.08889 8.39999 10.4121 8.39999 12.0444Z"
                  fill="currentColor"></path>
                <path
                  d="M22.4 26.8222V22.3889C22.4 20.8312 22.0195 19.3671 21.351 18.0949C21.6863 18.0039 22.0378 17.9556 22.4 17.9556C24.7197 17.9556 26.6 19.9404 26.6 22.3889V26.8222H22.4Z"
                  fill="currentColor"></path>
                <path
                  d="M6.64896 18.0949C5.98058 19.3671 5.59999 20.8312 5.59999 22.3889V26.8222H1.39999V22.3889C1.39999 19.9404 3.2804 17.9556 5.59999 17.9556C5.96219 17.9556 6.31367 18.0039 6.64896 18.0949Z"
                  fill="currentColor"></path>
              </svg>
            </div>

            <div class="mx-5">
              <h4 class="text-2xl font-semibold text-gray-700">4644</h4>
              <div class="text-gray-500">New Users</div>
            </div>
          </div>
        </div>
      </div> -->

      <div class="col-span-4 sm:col-span-9">
        <div class="bg-white shadow rounded-lg p-6">
          <h2 class="text-3xl font-extrabold tracking-tight text-gray-500 sm:text-4xl dark:text-white">Summary</h2>
          <div class="max-w-7xl mx-auto px-0 sm:px-6 lg:py-5 lg:px-8">
            <div class="grid grid-cols-1 gap-5 sm:grid-cols-4">
              <div class="bg-white overflow-hidden shadow sm:rounded-lg dark:bg-gray-900">
                <div class="p-4 sm:p-6">
                  <dl v-if="loader.rfp.loading === false">
                    <dt class="text-sm leading-5 font-medium text-gray-500 truncate dark:text-gray-400">
                      {{ datas.rfp.title }}</dt>
                    <dd class="mt-1 text-3xl leading-9 font-semibold text-indigo-600 dark:text-indigo-400">
                      {{ datas.rfp.nb_element }}
                    </dd>
                  </dl>
                  <dl v-else>
                    <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                  </dl>
                </div>
              </div>
              <div class="bg-white overflow-hidden shadow sm:rounded-lg dark:bg-gray-900">
                <div class="px-4 py-5 sm:p-6">
                  <dl v-if="loader.rfc.loading === false">
                    <dt class="text-sm leading-5 font-medium text-gray-500 truncate dark:text-gray-400">
                      {{ datas.rfc.title }}</dt>
                    <dd class="mt-1 text-3xl leading-9 font-semibold text-indigo-600 dark:text-indigo-400">
                      {{ datas.rfc.nb_element }}
                    </dd>
                  </dl>
                  <dl v-else>
                    <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                  </dl>
                </div>
              </div>
              <div class="bg-white overflow-hidden shadow sm:rounded-lg dark:bg-gray-900">
                <div class="px-4 py-5 sm:p-6">
                  <dl v-if="loader.vacancies.loading === false">
                    <dt class="text-sm leading-5 font-medium text-gray-500 truncate dark:text-gray-400">
                      {{ datas.vacancies.title }}</dt>
                    <dd class="mt-1 text-3xl leading-9 font-semibold text-indigo-600 dark:text-indigo-400">
                      {{ datas.vacancies.nb_element }}
                    </dd>
                  </dl>
                  <dl v-else>
                    <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                  </dl>
                </div>
              </div>
              <div class="bg-white overflow-hidden shadow sm:rounded-lg dark:bg-gray-900">
                <div class="px-4 py-5 sm:p-6">
                  <dl v-if="loader.service.loading === false">
                    <dt class="text-sm leading-5 font-medium text-gray-500 truncate dark:text-gray-400">
                      {{ datas.service.title }}</dt>
                    <dd class="mt-1 text-3xl leading-9 font-semibold text-indigo-600 dark:text-indigo-400">
                      {{ datas.service.nb_element }}
                    </dd>
                  </dl>
                  <dl v-else>
                    <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-white shadow rounded-lg pa-4 my-2">
          <h2 class="text-3xl font-extrabold tracking-tight text-gray-500 sm:text-4xl dark:text-white">Pricing
          </h2>
          <div class="max-w-7xl mx-auto px-0 sm:px-6 lg:py-4 lg:px-8">
            <pricing-resume-card :datas="{ company: datas.company, user: datas.professionals }"></pricing-resume-card>
          </div>
        </div>
        <div class="bg-white shadow rounded-lg pa-4 my-2">
          <h2 class="text-3xl font-extrabold tracking-tight text-gray-500 sm:text-4xl dark:text-white  mt-15">Activity
          </h2>
          <div class="max-w-7xl mx-auto px-0 sm:px-6 lg:py-5 lg:px-8">
            <card-linear-chart :datasheet="activitySheet"></card-linear-chart>
          </div>
        </div>
      </div>
    </div>

    <div v-bind:class="{ 'hidden': openTab !== 2, 'block': openTab === 2 }">
      <v-row class="pa-0">
        <v-col cols="12" md="5" class="pa-0">
          <account-infos></account-infos>
        </v-col>
        <v-col cols="12" md="7" class="pr-2">
          <account-settings></account-settings>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mdiAccountOutline, mdiLockOpenOutline, mdiInformationOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import AccountSettings from './account/AccountSettings.vue'
import AccountInfos from './account/AccountInfos.vue'
import HeaderProfil from '@/views/dashboard/components/HeaderProfil.vue'
import CardLinearChart from '@/views/dashboard/components/CardLinearChart.vue'
import PricingResumeCard from '../components/PricingResumeCard.vue';
import { Drequest } from '@/plugins/Drequest'

export default {
  components: {
    AccountSettings,
    AccountInfos,
    HeaderProfil,
    CardLinearChart,
    PricingResumeCard
  },
  data() {
    return {
      active_tab: 'company.profile',

      activitySheet: {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [{
          label: 'Call for proposals',
          data: [5, 0, 10, 5, 2, 3, 14],
          backgroundColor: 'rgba(255, 99, 132, 0.1)',
          borderColor: 'rgba(255, 99, 132, 0.6)',
          borderWidth: 1
        },
        {
          label: 'Call for colaboration',
          data: [0, 2, 4, 1, 4, 6, 2],
          backgroundColor: 'rgba(0, 0, 10, 0.1)',
          borderColor: 'rgba(0, 0, 10, 0.4)',
          borderWidth: 1
        },
        {
          label: 'Vacancies',
          data: [2, 6, 4, 5, 14, 6, 2],
          backgroundColor: 'rgba(24, 240, 200, 0.1)',
          borderColor: 'rgba(24, 240, 200, 0.8)',
          borderWidth: 1
        },
        {
          label: 'Service and product',
          data: [14, 10, 15, 5, 8, 12, 6],
          backgroundColor: 'rgba(11, 156, 49, 0.1)',
          borderColor: 'rgba(11, 156, 49, 0.8)',
          borderWidth: 1
        }]
      },

      datas: {
        rfp: {
          title: 'Call for proposals',
          values: {},
          nb_element: 0,
          sent: {
            title: "",
            value: 0
          },
          received: 0,
          confirm: 0
        },
        vacancies: {
          title: "Total vacancies",
          values: {},
          nb_element: 0
        },
        
        service: {
          title: "Services and products",
          values: {},
          nb_element: 0
        },
        rfc: {
          title: "Call for colaboration",
          values: {},
          nb_element: 0
        },
        company: {
          title: "Companies",
          values: {},
          nb_element: 0
        },
        professionals: {
          title: "Professionals",
          values: {},
          nb_element: 0
        }
      },
      loader: {
        vacancies: {
          loading: true,
          valueReady: false
        },
        
        service: {
          loading: true,
          valueReady: false
        },
        rfp: {
          loading: true,
          valueReady: false
        },
        rfc: {
          loading: true,
          valueReady: false
        },
        company: {
          loading: true,
          valueReady: false
        },
        professionals: {
          loading: true,
          valueReady: false
        }
      },
      company: {},
      tabs: [
        { title: 'Informations', icon: mdiAccountOutline, link: { name: 'company.account.profile' } },
        { title: 'Edit profil', icon: mdiAccountOutline, link: { name: 'company.account.setting' } },
      ],
      openTab: 1,
      tabs: [
        { title: 'Activity', icon: mdiAccountOutline },
        { title: 'profile', icon: mdiAccountOutline },
        // { title: 'Invite someone', icon: mdiEye }
      ],
    }
  },

  methods: {
    toggleTabs: function (tabNumber) {
      this.openTab = tabNumber
    },
  },
  beforeMount() {

    Drequest.api(`lazyloading.user`).get((response) => {
      if (response.success === true) {
        this.datas.professionals.nb_element = response.nb_element
        this.loader.professionals.loading = false
        this.loader.professionals.valueReady = true
        console.log(response)
      }
      else {
        this.loader.professionals.loading = false
        this.loader.professionals.valueReady = false
      }
    }).catch((err) => {
      this.loader.professionals.loading = false
      this.loader.professionals.valueReady = false

    });
    Drequest.api(`lazyloading.enterprise`).get((response) => {
      if (response.success === true) {
        this.datas.company.nb_element = response.nb_element
        this.loader.company.loading = false
        this.loader.company.valueReady = true
        console.log(response)
      }
      else {
        this.loader.company.loading = false
        this.loader.company.valueReady = false
      }
    }).catch((err) => {
      this.loader.company.loading = false
      this.loader.company.valueReady = false

    });


    // Drequest.api(`lazyloading.colaborator?dfilters=on&user_id:eq=${this.$store.getters.getUser.id}`).get((response) => {
    //   if (response.success === true) {
    //     this.datas.rfc.nb_element = response.nb_element
    //     // this.dashservices[0].items.push({ title: 'Sent', value: response.nb_element })

    //     this.loader.rfc.loading = false
    //     this.loader.rfc.valueReady = true
    //     console.log( response)
    //   }
    //   else {
    //     this.dashservices[0].items.push({ title: 'Sent', value: 0 }),
    //       this.loader.rfc.loading = false
    //     this.loader.rfc.valueReady = false
    //   }
    // }).catch((err) => {
    //   this.loader.rfc.loading = false
    //   this.loader.rfc.valueReady = false
    // });

    // Drequest.api(`lazyloading.colaborator?dfilters=on&recipientuser:eq=${this.$store.getters.getUser.id}`).get((response) => {
    Drequest.api(`lazyloading.rfp?dfilters=on&enterprise_id=${this.$store.getters.getCompany.id}`).get((response) => {
      if (response.success === true) {
        this.datas.rfp.nb_element = response.nb_element
        this.loader.rfp.loading = false
        this.loader.rfp.valueReady = true
        console.log(response)
      }
      else {
        this.loader.rfp.loading = false
        this.loader.rfp.valueReady = false
      }
    }).catch((err) => {
      this.loader.rfp.loading = false
      this.loader.rfp.valueReady = false
    });

    //   Drequest.api(`lazyloading.colaborator?dfilters=on&status:eq=2&user_id:eq=${this.$store.getters.getUser.id}&recipientuser:oreq=${this.$store.getters.getUser.id}`).get((response) => {
    //     if (response.success === true) {
    //       this.networkdata.confirm = response.nb_element
    //       // this.dashservices[0].items.push({ title: 'Colaborators', value: response.nb_element })
    //     }
    //     else { this.dashservices[0].items.push({ title: 'Colaborators', value: 0 }) }
    //   }).catch((err) => { });

    Drequest.api(`lazyloading.rfc?dfilters=on&enterprise_id=${this.$store.getters.getCompany.id}`).get((response) => {
      if (response.success === true) {
        this.datas.rfc.nb_element = response.nb_element
        this.loader.rfc.loading = false
        this.loader.rfc.valueReady = true
        console.log(response)
      }
      else {
        this.loader.rfc.loading = false
        this.loader.rfc.valueReady = false
      }
    }).catch((err) => {
      this.loader.rfc.loading = false
      this.loader.rfc.valueReady = false
    });

    Drequest.api(`lazyloading.job?dfilters=on&enterprise_id:eq=${this.$store.getters.getCompany.id}`).get((response) => {
      if (response.success === true) {
        this.datas.vacancies.nb_element = response.nb_element
        this.loader.vacancies.loading = false
        this.loader.vacancies.valueReady = true
        console.log(response)
      }
      else {
        this.loader.vacancies.loading = false
        this.loader.vacancies.valueReady = false
      }
    }).catch((err) => {
      this.loader.vacancies.loading = false
      this.loader.vacancies.valueReady = false
    });

    Drequest.api(`lazyloading.service?dfilters=on&enterprise_id:eq=${this.$store.getters.getCompany.id}`).get((response) => {
      if (response.success === true) {
        this.datas.service.nb_element = response.nb_element
        this.loader.service.loading = false
        this.loader.service.valueReady = true
        console.log(response)
      }
      else {
        this.loader.service.loading = false
        this.loader.service.valueReady = false
      }
    }).catch((err) => {
      this.loader.service.loading = false
      this.loader.service.valueReady = false
    });

  },
  setup() {
    return {
      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/tabs.scss';
</style>
